import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useTranslate } from 'stylesHooks';
import { claimStatusIds } from 'types/Claims';
import {
	SERVICES_SHORT_TRANSLATION_KEY,
	TEMP_SERVICES,
	TRANSLATION_KEYS,
} from 'utils/constants';

export const useGetClaimStatusProperty = () => {
	const { claimsStatus } = useSelector((store: RootState) => store.claims);

	return (statusId: claimStatusIds) =>
		claimsStatus.find(el => el.id === statusId) || {
			id: 0,
			name: '',
			label: '',
			groupCode: '',
			groupLabel: '',
		};
};
export const useGetServiceTypeInitials = () => {
	const { t: tDBLabels } = useTranslate(TRANSLATION_KEYS.DB_LABELS);
	// TODO: temporary commented for next release
	// const { servicesMap } = useSelector((state: RootState) => state.claim);

	const servicesMap = TEMP_SERVICES;

	const getInitialLabel = useCallback(
		(serviceType: string | undefined) => {
			if (!serviceType) {
				return '-';
			}

			const matching = servicesMap.find(
				service =>
					service.code.toLowerCase().replace('-', '') ===
					serviceType.toLowerCase().replace(' ', ''),
			);

			if (!matching) {
				return '-';
			}

			return tDBLabels(`${matching.label}.${SERVICES_SHORT_TRANSLATION_KEY}`);
		},
		[servicesMap, tDBLabels],
	);

	return {
		getInitialLabel,
	};
};
